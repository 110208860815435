export const mainApiBaseUrl = import.meta.env.ALDRIN_ERP_MAIN_API_URL ?? "";

export const apiBaseURL = import.meta.env.API_URL ?? "http://localhost:3012";

export const reCaptchaSiteKey =
  import.meta.env.RECAPTCHA_SITE_KEY ??
  "6LcXg44jAAAAAEZZg0XXhvLrSrEMRAhy-59HQHBm";

export const rollbarConfig = {
  accessToken:
    import.meta.env.ROLLBAR_ACCESS_TOKEN ?? "1b78b60023124c4ab882ae1a037b08fe",
  // environment: import.meta.env.NODE_ENV ?? "development",
  transmit: import.meta.env.ROLLBAR_TRANSMIT === "true",
};

export const openobserveDisabled =
  import.meta.env.OPENOBSERVE_RUM_DISABLED === "true";

export const openobserveRumInit = {
  clientToken: import.meta.env.OPENOBSERVE_RUM_CLIENT_TOKEN,
  site: import.meta.env.OPENOBSERVE_RUM_SITE,
  organizationIdentifier:
    import.meta.env.OPENOBSERVE_RUM_ORGANIZATION || "default",
};
